<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">年齢認証ページの設定</h1>
        <banner-hint>
          年齢認証ページはコンテンツが薄くなりがちですが検索流入に対してのランディングページ、ホームページの顔として検索ポータルにホームページの紹介をする役割があります。
          検索順位上昇のためにお店紹介文の内容を豊富にしましょう。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="mb-5 py-7 px-6"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-form ref="form-images">
                <num-instruction number="1">年齢認証ページ上部に表示されるメインの店舗ロゴと背景画像を選びます。</num-instruction>
                <!-- ******************************************** -->
                <!-- ロゴ画像 -->
                <!-- ******************************************** -->
                <v-row class="mt-7 mb-10 px-4">
                  <v-col :cols="siteTopData.top_image_url ? 10 : 12"
                    class="py-0"
                  >
                    <v-file-input
                      v-model="selectedImage['top_image_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      required
                      prepend-icon="mdi-image"
                      label="ロゴ画像１（PC等の横長の画面用）"
                      hint="横x縦の推奨比率 [1 : 1] ~ [1.5 : 1] *各画像上限サイズは1MB"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n4"
                    v-if="siteTopData.top_image_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="60"
                      contain
                      :src="siteTopData.top_image_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="py-0 mt-n2 d-flex justify-end">
                    <v-btn
                      v-if="siteTopData.top_image_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('top_image_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <v-row class="my-10 px-4">
                  <!-- トップ画 - モバイル -->
                  <v-col :cols="siteTopData.top_image_mobile_url ? 10 : 12"
                    class="py-0"
                  >
                    <v-file-input
                      v-model="selectedImage['top_image_mobile_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="ロゴ画像２（スマホ等の縦長の画面用）"
                      hint="推奨比率 [1 : 1] ~ [1 : 1.5] *未設定の場合はロゴ画像1を使用"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n4"
                    v-if="siteTopData.top_image_mobile_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="60"
                      contain
                      :src="siteTopData.top_image_mobile_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="py-0 mt-n2 d-flex justify-end">
                    <v-btn
                      v-if="siteTopData.top_image_mobile_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('top_image_mobile_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <v-row class="my-10 px-4">
                  <!-- トップBG画 -->
                  <v-col :cols="siteTopData.bg_image_url ? 10 : 12"
                    class="py-0"
                  >
                    <v-file-input
                      v-model="selectedImage['bg_image_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="ロゴの背景画像１（PC等の横長の画面用）"
                      hint="*未設定の場合は基本デザインのHP全体の背景を使用"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n4"
                    v-if="siteTopData.bg_image_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="60"
                      contain
                      :src="siteTopData.bg_image_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="py-0 mt-n2 d-flex justify-end">
                    <v-btn
                      v-if="siteTopData.bg_image_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('bg_image_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-10 mb-5 px-4">
                  <!-- BG画 - モバイル -->
                  <v-col :cols="siteTopData.bg_image_mobile_url ? 10 : 12"
                    class="py-0"
                  >
                    <v-file-input
                      v-model="selectedImage['bg_image_mobile_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="ロゴの背景画像２（スマホ等の縦長の画面用）"
                      hint="*未設定の場合はロゴの背景画像1と同じ設定"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n4"
                    v-if="siteTopData.bg_image_mobile_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="60"
                      contain
                      :src="siteTopData.bg_image_mobile_url"
                    >
                    </v-img>
                  </v-col>
                      <v-col class="py-0 mt-n2 d-flex justify-end">
                    <v-btn
                      v-if="siteTopData.bg_image_mobile_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('bg_image_mobile_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- ボタン -->
                  <v-col cols="12">
                    <v-btn
                      :disabled="!exstsImagesToUpload"
                      depressed
                      color="primary"
                      @click="updateImages()"
                    >画像を登録</v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <!-- ******************************************** -->
              <!-- 紹介文 -->
              <!-- ******************************************** -->
              <v-form ref="form-introduction"
                class="mt-15"
              >
                <v-row class="mt-5 px-3">
                  <num-instruction number="2">エディターを使ってお店の紹介文を作成します。</num-instruction>

                  <!-- エディター -->
                  <v-col cols="12">
                    <icon-info icon="lightbulb-on-outline" class="mb-5">
                      ロゴ画像下部に表示される内容で、お店の特徴やコンセプトを入力することでHPの検索順位上昇に繋がります。
                    </icon-info>
                    <tiny-editor
                      v-if="readyTinyEditor"
                      v-bind:text.sync="siteTopData.introduction"
                      :apiAdmin="apiAdmin"
                      :shopData="shopData"
                      :contentStyle="contentStyle"
                      :max="textMax"
                      :height="700"
                    />
                  </v-col>
                </v-row>
                <v-row class="px-7 mt-3">
                  <v-select
                    class="pt-0"
                    v-model="siteTopData.introduction_bg_color"
                    :items="introBgColorList"
                    item-text="label"
                    item-value="value"
                    color="primary"
                    label="紹介文の背景色"
                    @change="updateRow('introduction_bg_color')"
                  ></v-select>
                  <v-spacer></v-spacer>
                  <!-- ボタン -->
                  <v-btn class="mt-2 d-flex justify-end"
                    depressed
                    color="primary"
                    @click="updateRow('introduction')"
                  >更新</v-btn>
                  <v-btn
                    class="mt-2 ml-2"
                    text
                    depressed
                    color="primary"
                    @click="deleteRow('introduction')"
                  >削除</v-btn>
                </v-row>
              </v-form>

              <!-- ******************************************** -->
              <!-- フリースペース -->
              <!-- ******************************************** -->
              <v-form ref="form-free_space"
                class="mt-15 px-3"
              >
                <v-row>
                  <num-instruction number="3">必要に応じてHP入り口ボタン下のフリースペースに "HTMLタグ" を設置できます。</num-instruction>

                  <v-col cols="12">
                    <icon-info icon="lightbulb-on-outline" class="mb-5">
                      外部ポータルサイト等からリンクバナー設置の指定がある場合などにお使いいただけます。
                    </icon-info>
                    <v-textarea
                      v-model="siteTopData.free_space"
                      filled
                      auto-grow
                      counter="2000"
                      label=""
                      :rules="[valiRules.max2000]"
                    ></v-textarea>
                  </v-col>
                  <!-- ボタン -->
                  <v-col class="pt-0 d-flex justify-end">
                    <v-btn
                      depressed
                      color="primary"
                      @click="updateRow('free_space')"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed
                      color="primary"
                      @click="deleteRow('free_space')"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import NumberInstruction from "@/components/_NumberInstruction.vue";
import IconInfo from "@/components/_IconInfo.vue";
import BannerHint from "@/components/_BannerHint.vue";
import TinyEditor from "@/components/_TinyEditor.vue"
// import { VueEditor } from "vue2-editor"

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
    'num-instruction': NumberInstruction,
    'tiny-editor': TinyEditor,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      siteTopData: {introduction: ''},
      selectedImage: {},
      textMax: 10000,
      siteSetting: {},
      contentStyle: '',
      readyTinyEditor: false,
      introBgColorList: [
        {label: 'メインカラー', value: 'primary'},
        {label: 'サブカラー', value: 'secondary'},
        {label: '白', value: 'white'},
        {label: '黒', value: 'black'},
        {label: '透明', value: 'transparent'},
      ],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    exstsImagesToUpload() {
      for (let key in this.selectedImage) {
        if (this.selectedImage[key]) return true
      }
      return false
    },
  },

  watch: {
    'siteTopData.introduction_bg_color': function(now) {
      this.readyTinyEditor = false

      this.contentStyle = 'body#tinymce {color:' + (this.siteSetting.is_dark_theme ? 'white' : 'black')

      if (now === 'transparent') {
        this.contentStyle += '; background-color:' + now +
                             '; background-image:url(' + this.siteSetting.site_bg_image_url + ')'
      }
      else if (now === 'primary' || now === 'secondary' ) {
        this.contentStyle += '; background-color:' + this.siteSetting[now + '_color']
      }
      else {
        this.contentStyle += '; background-color:' + now
      }

      this.contentStyle += ';} p {margin: .5em 0;}'

      setTimeout(() => { this.readyTinyEditor = true }, 0)
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getSiteSetting(),
        this.getSiteTop(),
      ])

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    getSiteTop() {
      return this.adminApi.getReqWithAuth('site-top/').then( data => {
        if (!data) return

        this.siteTopData = data
      })
    },

    // API req: GET site_setting
    getSiteSetting() {
      return this.adminApi.getReqWithAuth('site-setting/').then( data => {
        if (!data) return
        this.siteSetting = data
      })
    },

    //**************************************************
    //登録＆更新
    //**************************************************
    updateRow(columnName) {
      if (columnName === 'free_space' && !this.$refs['form-' + columnName].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPath = 'update/site-top/column/' + columnName
      const formData = new FormData()
      formData.append(columnName, this.siteTopData[columnName])

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //画像登録＆更新
    //**************************************************
    updateImages() {
      if (!this.$refs['form-images'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const formData = new FormData()
      formData.append("image_url_" + 1, this.selectedImage['top_image_url'] || 'no update')
      formData.append("image_url_" + 2, this.selectedImage['top_image_mobile_url'] || 'no update')
      formData.append("image_url_" + 3, this.selectedImage['bg_image_url'] || 'no update')
      formData.append("image_url_" + 4, this.selectedImage['bg_image_mobile_url'] || 'no update')

      this.adminApi.apiReqWithData('PUT', 'update/site-top/images/', formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
          return
        }

        // 選択の削除
        for (let key in this.selectedImage) {
          delete this.selectedImage[key]
        }
        // サムネイル
        for (let key in response) {
          if (response[key]) this.siteTopData[key] = response[key]
        }

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(columnName) {
      const apiPath = 'delete/site-top/column/' + columnName

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.siteTopData[columnName] = ''

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
